<template>
  <div class="page-content">
    <page-breadcrumb title="Scenario list" class="mb-2" />

    <data-table-ssr id="item_list" ref="item_list"
      :create-row-fn="createItem" :create_schema="create_schema"
      :get-list-fn="getList" :columns="item_fields"
      :to-edit-fn="toEditPage"
      :delete-row-fn="deleteItem"
    />
  </div>
</template>

<script>
import { removeEmpty } from '@core/utils/index'
import service from '../service'
const create_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
      { label: 'Background', field: 'background', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Title', field: 'title', input_type: 'text', validate: { required: true }, },
      { label: 'Career field', field: 'career_field', input_type: 'text', validate: { required: true } },
      { label: 'Position', field: 'position', input_type: 'text', validate: { required: true } },
    ]
  }
];

const item_fields = [
  { label: 'Title', field: 'title' },
  { label: 'Image', field: 'image', input_type: 'image' },
  { label: 'Background', field: 'background', input_type: 'image' },
  { label: 'Career field', field: 'career_field' },
  { label: 'Position', field: 'position' },
  { label: 'Created At', field: 'createdAt', input_type: 'date' },
];

export default {
  data(){
    return{
      create_schema,
      item_fields,
      total: 0,
      editing_data: null,
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async getList({ limit, page, query }) {
      query = removeEmpty(query);
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async toEditPage({ _id }) {
      this.$router.push({ name: 'ai_scenario-detail', params: { id: _id } });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
  }
}
</script>
